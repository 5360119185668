import React, { useState } from 'react'
import { keyframes } from '@emotion/core'

import { Colors, css, fontSizes, fontWeights } from '../lib/theme'
import { getColWidth, getTiltCoords } from '../lib/utils'

import { Flex } from './Grid'

const introAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: `translate3d(0, 30px, 0)`,
  },
  '80%': {
    transform: `translate3d(0, 0, 0)`,
  },
  '100%': {
    opacity: 1,
  },
})

const styles = {
  container: css({
    animation: `${introAnimation} 900ms cubic-bezier(0.175, 0.35, 0.32, 0.8) both 200ms`,
    minHeight: [null, null, 'calc(100vh - 63px)'], // TODO: Store header's height in a variable
    paddingBottom: [50, 160, 0],
    paddingTop: [50, 180, 0],
    perspective: '500px',
    opacity: 0,
  }),
  introContainer: css({
    boxSizing: 'border-box',
    margin: '0 auto',
    maxWidth: '68.75rem', // 1100px
    paddingLeft: 20,
    paddingRight: 20,
    width: ['100%', '100%', getColWidth(10), getColWidth(6), getColWidth(8)],
  }),
  intro: css({
    color: Colors.WHITE,
    fontSize: [
      fontSizes.S24,
      fontSizes.S33,
      fontSizes.S33,
      fontSizes.S33,
      fontSizes.S36,
      fontSizes.S42,
    ],
    fontWeight: fontWeights.REGULAR,
    lineHeight: 1.56,
    marginTop: [0, -63], // TODO: Store header's height in a variable
    transition: 'transform 300ms ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  }),
  introHightlight: css({
    fontWeight: fontWeights.HEAVY,
  }),
  link: css({
    color: Colors.WHITE,
    position: 'relative',
    textDecoration: 'none',
    transition: 'all 300ms ease-in-out',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    ':after, :before': {
      bottom: 6,
      content: '""',
      height: 1,
      left: 0,
      position: 'absolute',
      transition:
        'background 350ms ease-in-out, transform 230ms ease-in-out, height 200ms ease-in-out',
      width: '100%',
    },
    ':after': {
      background: Colors.WHITE,
      transformOrigin: '0 0',
      transform: `scaleX(0)`,
    },
    ':before': {
      background: Colors.RED,
    },
    ':hover': {
      color: Colors.WHITE,
      transform: 'scale(1.03) translateY(-1px)',
    },
    ':hover:after': {
      height: 3,
      transform: `scaleX(1) translateY(1px)`,
    },
    ':hover:before': {
      height: 3,
      transform: `translateY(1px)`,
    },
  }),
}

const IntroLink = ({ label, url }) => (
  <a css={styles.link} href={url} rel='noopener noreferrer' target='_blank'>
    {label}
  </a>
)

const handleMouseMove = setRotateCoords => e => {
  const { clientX, clientY } = e
  const { x, y } = getTiltCoords(clientX, clientY)

  setRotateCoords({ x, y })
}

const Intro = () => {
  const [rotateCoords, setRotateCoords] = useState({ x: 0, y: 0 })
  const { x, y } = rotateCoords
  const onMouseMove = handleMouseMove(setRotateCoords)

  return (
    <Flex
      css={styles.container}
      alignItems='center'
      //  TODO: Use gutter value from constants
      px={40}
      onMouseMove={onMouseMove}>
      <div
        css={styles.introContainer}
        style={{
          transform: `rotateX(${x}deg) rotateY(${y}deg)`,
        }}>
        <p css={styles.intro}>
          <span css={styles.introHightlight}>Gaston Figueroa</span> is a Senior
          Front-End Developer in Portland, Oregon. He writes code for the great
          folks at <IntroLink label='Work & Co' url='https://work.co' /> where
          he’s had the privilege to work with brands like{' '}
          <IntroLink label='Apple' url='https://work.co/news/today-at-apple/' />,
          Amazon,{' '}
          <IntroLink
            label='Virgin America'
            url='https://work.co/news/cannes-lions-2015'
          />
          , <IntroLink label='Aēsop' url='https://work.co/clients/aesop/' />,
          Lyft,{' '}
          <IntroLink
            label='Rapha'
            url='https://work.co/news/rapha-comm-arts/'
          />
          , Fox,{' '}
          <IntroLink label='Gatorade' url='https://work.co/news/gatorade/' />,
          and Red&nbsp;Robin.
        </p>
      </div>
    </Flex>
  )
}

export default Intro
