import React from 'react'
import { compose } from 'recompose'
import { withScroll, withWindow } from 'react-window-decorators'

import Experiments from '../components/Experiments'
import Intro from '../components/Intro'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Skills from '../components/Skills'
import withIsMobile from '../components/hocs/withIsMobile'

const IndexPage = props => {
  const { dimensions, isMobile, scrollPositionY } = props

  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[`developer`, `front-end developer`, `react`]}
      />
      <Intro />
      <Skills dimensions={dimensions} scrollPositionY={scrollPositionY} />
      <Experiments dimensions={dimensions} isMobile={isMobile} />
    </Layout>
  )
}

export default compose(
  withWindow,
  withScroll,
  withIsMobile
)(IndexPage)
