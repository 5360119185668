export const getTiltCoords = (clientX, clientY) => {
  const halfContainerWidth = window.innerWidth * 0.5
  const halfContainerHeight = window.innerHeight * 0.5
  const xDeg = window.innerWidth * 0.001
  const yDeg = window.innerHeight * 0.0025

  // Distance from center
  const distFromCenter = {
    x: (clientX - halfContainerWidth) / halfContainerWidth,
    y: (clientY - halfContainerHeight) / halfContainerHeight,
  }

  return {
    x: distFromCenter.y * yDeg,
    y: distFromCenter.x * xDeg,
  }
}
