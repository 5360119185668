import React, { useState } from 'react'

import { Colors, css, Easings, fontSizes, fontWeights } from '../lib/theme'
import { Box, Flex } from './Grid'
import { getColWidth, getTiltCoords } from '../lib/utils'

import compadre from '../images/compadre.gif'
import htc from '../images/htc.jpg'
import traffic from '../images/traffic.jpg'
import piggy from '../images/piggy.jpg'
import firstRace from '../images/first-race.jpg'
import yearOfRunning from '../images/year-of-running.jpg'

const mock = [
  {
    date: 'MAY-2018',
    name: 'Compadre App',
    url: 'https://github.com/gastonfig/compadre/',
    year: '2018',
    imgUrl: compadre,
  },
  {
    date: 'NOV-2016',
    name: 'Office Traffic Viz',
    url: 'https://gastonfig.github.io/office-traffic/',
    year: '2016',
    imgUrl: traffic,
  },
  {
    date: 'SEP-2016',
    name: "Hood to Coast '16",
    url: 'https://gastonfig.github.io/htc-16/',
    year: '2016',
    imgUrl: htc,
  },
  {
    date: 'APR-2016',
    name: 'Whack a Piggy',
    url: 'https://github.com/gastonfig/whack-a-piggy/',
    year: '2016',
    imgUrl: piggy,
  },
  {
    date: 'MAR-2016',
    name: 'One Year of Running',
    url: 'https://gastonfig.github.io/one-year-of-running/',
    year: '2016',
    imgUrl: yearOfRunning,
  },
  {
    date: 'JUN-2014',
    name: 'My First Race',
    url: 'https://gastonfig.github.io/my-first-race/',
    year: '2014',
    imgUrl: firstRace,
  },
]

const styles = {
  container: css({
    paddingBottom: [140, 140, 210],
    paddingTop: [140, 140, 200],
    perspective: '450px',
    position: 'relative',
  }),
  image: css({
    height: 'auto',
    opacity: 0,
    position: 'absolute',
    right: ['-20%', '-20%', '-30%', '-30%', '-35%', 0],
    top: '-20%',
    transform: 'scale(0.98) translate3d(-12%, 0,0)',
    transformOrigin: 'center 0',
    transition: `opacity 600ms ${Easings.CUSTOM}, transform 400ms ${
      Easings.CUSTOM
    }`,
    width: '60vh',
    zIndex: -1,
  }),
  itemLink: css({
    alignItems: 'baseline',
    color: Colors.WHITE,
    display: 'flex',
    flexDirection: ['column', 'column', 'initial'],
    letterSpacing: -0.35,
    padding: '20px 0',
    textDecoration: 'none',
    transition: `opacity 400ms ${Easings.CUSTOM}, letter-spacing 350ms ${
      Easings.CUSTOM
    }, transform 350ms ${Easings.CUSTOM}`,
    transform: 'scale(1)',
    transformOrigin: '0 0',

    // && used to increase specificity
    '&&:hover': {
      letterSpacing: 0.6,
      opacity: 1,
      transform: 'scale(1.02)',
    },
  }),
  itemName: css({
    fontSize: [fontSizes.S33, fontSizes.S54, fontSizes.S69],
    fontWeight: fontWeights.HEAVY,
  }),
  itemsList: css({
    position: 'relative',
    zIndex: 1,
    ':hover a': {
      opacity: 0.2,
    },
  }),
  itemYear: css({
    color: Colors.GRAY_MEDIUM,
    fontSize: fontSizes.S16,
    fontWeight: fontWeights.BOLD,
    lineHeight: [1, 1, 'inherit'],
    marginRight: 10,
    whiteSpace: 'nowrap',
    '&:after': {
      content: '"—"',
      display: ['block', 'block', 'inline-block'],
    },
  }),
  listItem: css({
    listStyle: 'none',
    margin: 0,
    position: 'relative',

    // && used to increase specificity
    '&&:hover img': {
      opacity: 0.85,
      transform: 'scale(1) translate3d(0, 0, 0)',
    },
  }),
  title: css({
    fontSize: fontSizes.S24,
    marginBottom: [80, 110],

    '&:after': {
      content: '"—"',
      display: 'block',
    },
  }),
}

const handleMouseMove = setRotateCoords => e => {
  const { clientX, clientY } = e
  const { x, y } = getTiltCoords(clientX, clientY)

  setRotateCoords({ x, y })
}

const Item = ({ item, shouldDisplayImage }) => (
  <li css={styles.listItem}>
    <a
      css={styles.itemLink}
      href={item.url}
      rel="noopener noreferrer"
      target="_blank">
      <span css={styles.itemYear}>{item.year} </span>{' '}
      <h3 css={styles.itemName}>{item.name}</h3>
    </a>
    {shouldDisplayImage && (
      <img src={item.imgUrl} alt="Logo" css={styles.image} />
    )}
  </li>
)

const Experiments = ({ isMobile }) => {
  const [rotateCoords, setRotateCoords] = useState({ x: 0, y: 0 })
  const { x, y } = rotateCoords
  const onMouseMove = handleMouseMove(setRotateCoords)

  return (
    <div css={styles.container} onMouseMove={onMouseMove}>
      <Flex alignItems="center" px={40}>
        <Box
          ml={[0, 0, getColWidth(1), getColWidth(2)]}
          width={[1, 1, 10 / 12, 8 / 12]}>
          <h2 css={styles.title}>
            Side Projects <br />& Experiments
          </h2>
        </Box>
      </Flex>

      <Flex
        alignItems="center"
        px={40}
        style={{
          transform: `rotateX(${x}deg) rotateY(${y}deg)`,
        }}>
        <Box
          as="ul"
          css={styles.itemsList}
          ml={[0, 0, getColWidth(1), getColWidth(2)]}
          width={[1, 1, 10 / 12, 8 / 12]}>
          {mock &&
            mock.map((item, index) => (
              <Item
                key={item.name}
                item={item}
                index={index}
                shouldDisplayImage={!isMobile}
              />
            ))}
        </Box>
      </Flex>
    </div>
  )
}

export default Experiments
