import React from 'react'

import { breakpoints } from '../../lib/theme'
import { remToPx } from '../../lib/utils'

const withIsMobile = WrappedComponent => {
  const WithIsMobile = props => {
    const isMobile = props.dimensions.width < remToPx(breakpoints[1])

    return <WrappedComponent isMobile={isMobile} {...props} />
  }

  return WithIsMobile
}

export default withIsMobile
