import React, { useState } from 'react'

import { Colors, css, fontWeights } from '../lib/theme'
import { getColWidth, getTiltCoords, lerp, normalize } from '../lib/utils'

import { Box, Flex } from './Grid'

const Refs = {
  containerRef: React.createRef(),
}

let skillsTopPos = 0

const mock = {
  skills: [
    {
      name: 'core',
      items: [
        'JavaScript',
        'React',
        'Redux',
        'Typescript',
        'Node.js',
        'CSS',
        'SASS',
        'Styled Components',
        'Good eye for design',
        'Attention to detail',
        'Animation/Motion',
        'Front-end perfomance',
      ],
    },
    {
      name: 'interests',
      items: [
        'Arduino',
        'Raspberry Pie',
        'Sensors',
        'Data Visualization',
        'Creative Code',
      ],
    },
    {
      name: 'learning',
      items: ['GraphQL', 'React Native', 'WebGL'],
    },
  ],
}

const styles = {
  container: css({
    background: Colors.WHITE_LIGHTER,
    color: Colors.BLACK,
    minHeight: '100vh',
    paddingBottom: [10, 90, 100],
    paddingTop: [90, 110, 160],
    perspective: '500px',
    position: 'relative',
  }),
  skillsContainer: css({
    marginBottom: [30, 60, 80, 106],
  }),
  skillUl: css({
    columns: [null, 2],
    listStyle: 'none',
    marginLeft: 0,
  }),
  skillLi: css({
    color: Colors.GRAY_MEDIUM,
    fontSize: [18, 21, 24],
    lineHeight: ['33px', '39px', '43px'],
    marginBottom: 0,
  }),
  skillsTag: css({
    fontSize: '26.5vw',
    fontWeight: fontWeights.HEAVY,
    left: '-6vw',
    letterSpacing: '-1.2vw',
    opacity: 0.05,
    position: 'absolute',
    textTransform: 'uppercase',
    top: '30vh',
  }),
  title: css({
    fontSize: [24, 24, 33],
    fontWeight: fontWeights.HEAVY,
    letterSpacing: '-.025rem',
    lineHeight: '39px',
    marginBottom: 16,
    textTransform: 'capitalize',
  }),
}

const Skill = ({ skill }) => (
  <Flex css={styles.skillsContainer} px={40}>
    <Box
      ml={[0, 0, getColWidth(1), getColWidth(2)]}
      width={[1, 3 / 12, 3 / 12, 2 / 12]}>
      <h2 css={styles.title}>{skill.name}</h2>
    </Box>
    <Box width={[1, 9 / 12, 8 / 12, 6 / 12]}>
      <ul css={styles.skillUl}>
        {skill.items &&
          skill.items.map(item => (
            <li css={styles.skillLi} key={item}>
              {item}
            </li>
          ))}
      </ul>
    </Box>
  </Flex>
)

const getInterpolatedYPos = scrollPositionY => {
  if (Refs.containerRef.current) {
    const elemTop = Refs.containerRef.current.offsetTop
    const normalizedOffsetTop = normalize(scrollPositionY, elemTop)
    const targetYOffset = elemTop * -0.15
    const targetY = normalizedOffsetTop * targetYOffset

    skillsTopPos += lerp(skillsTopPos, targetY, 0.15)
  }

  return `${skillsTopPos}px`
}

const handleMouseMove = setRotateCoords => e => {
  const { clientX, clientY } = e
  const { x, y } = getTiltCoords(clientX, clientY)

  setRotateCoords({ x, y })
}

const Skills = ({ scrollPositionY }) => {
  const [rotateCoords, setRotateCoords] = useState({ x: 0, y: 0 })
  const { x, y } = rotateCoords
  const onMouseMove = handleMouseMove(setRotateCoords)

  return (
    <div
      css={styles.container}
      ref={Refs.containerRef}
      onMouseMove={onMouseMove}>
      <span
        css={styles.skillsTag}
        style={{
          transform: `
          translateY(${getInterpolatedYPos(scrollPositionY)})
          rotateX(${x}deg) rotateY(${y}deg)`,
        }}>
        skills
      </span>
      {mock.skills.map(skill => (
        <Skill key={skill.name} skill={skill} />
      ))}
    </div>
  )
}

export default Skills
